<template>
  <div id="clients_page_wrapper">
    <div class="bb-mobile-header">
      <div class="bb-mobile-header-left">
        <button class="mobile-sidebar-btn" @click="openMobileAside()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
      </div>
      <h1>{{ $t("clients.title") }}</h1>
      <div class="bb-mobile-header-right"></div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="bb-clients-table-search d-flex align-items-center">
        <i class="flaticon-search"></i>
        <input
          type="text"
          :placeholder="$t('clients.searchInput.placeholder')"
          v-model="searchTerm"
        />
      </div>
      <button class="bb-new-client-btn" @click="createClient">
        {{ $t("clients.createClientBtn") }}
      </button>
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="allClients"
      :search="searchTerm"
      hide-default-footer
      :page.sync="pagination.page"
      :items-per-page="pagination.itemsPerPage"
      @page-count="pagination.pageCount = $event"
      @pagination="updatePaginationText($event)"
      ref="clientsTable"
      class="bb-table clients-table"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-left" @click="showClient(item.id)">
            <div class="bb-badge">
              {{ item.initials }}
            </div>
          </td>
          <td class="text-left" @click="showClient(item.id)">
            {{ item.fullName }}
          </td>
          <td class="text-left" @click="showClient(item.id)">
            {{ item.phone }}
          </td>
          <td class="text-left" @click="showClient(item.id)">
            {{ item.email }}
          </td>
          <td class="text-right">
            <BBDropdown positionClass="right">
              <template v-slot:toggle>
                <i class="ki ki-more-ver"></i>
              </template>
              <template v-slot:items>
                <div class="action-item">
                  <router-link
                    :to="{ name: 'clientProfile', params: { id: item.id } }"
                  >
                    <i class="far fa-user"></i>
                    <span>{{ $t("clients.clientDropdown.showBtn") }}</span>
                  </router-link>
                </div>
                <div class="action-item" @click="editClient(item)">
                  <i class="flaticon2-gear"></i>
                  <span>{{ $t("clients.clientDropdown.editBtn") }}</span>
                </div>
                <div class="action-item danger" @click="deleteClient(item)">
                  <i class="far fa-trash-alt"></i>
                  <span>{{ $t("clients.clientDropdown.deleteBtn") }}</span>
                </div>
              </template>
            </BBDropdown>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="bb-table-footer clients-table-footer">
      <v-pagination
        class="bb-pagination"
        v-model="pagination.page"
        :length="pagination.pageCount"
      ></v-pagination>
      <div class="d-flex justufy-content-between align-items-center">
        <BBSelect
          class="bb-pagination-per-page-select"
          :options="pagination.perPageOptions"
          v-model="pagination.itemsPerPage"
        />
        <span class="bb-table-pagination-text">
          {{ $t("clients.table.pagination.show") }}
          {{ pagination.currentFromItem }} - {{ pagination.currentToItem }}
          {{ $t("clients.table.pagination.of") }}
          {{ pagination.currentTotalItems }}
        </span>
      </div>
    </div>
    <div id="mobile_clients_wrapper">
      <div class="bb-input-group search-input">
        <i class="fas fa-search"></i>
        <input
          type="text"
          v-model="searchTerm"
          :placeholder="$t('clients.mobileSearchInput.placeholder')"
        />
        <button class="bb-badge badge-rounded" @click="searchTerm = ''">
          <i class="ki ki-close icon-md"></i>
        </button>
      </div>
      <div class="clients-list">
        <div
          class="client-item"
          v-for="client in displayClients"
          :key="client.id"
          @click="showClient(client.id)"
        >
          <div class="d-flex justify-content-start align-items-center">
            <div class="bb-badge">
              {{ client.initials }}
            </div>
            <div class="client-details">
              <span>{{ client.fullName }}</span>
              <small>{{ client.email }}</small>
            </div>
          </div>
          <i class="la la-angle-right"></i>
        </div>
      </div>
      <button class="mobile-new-client-btn" @click="createClient">
        <i class="la la-plus"></i>
      </button>
    </div>
    <ClientFormModal ref="clientFormModal" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_CLIENTS } from "@/core/services/store/clients.module";
import BBDropdown from "@/view/content/BBDropdown.vue";
import { mapGetters } from "vuex";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
//import clientFormMixin from "@/core/mixins/clientFormMixin.js";
import clientFormModalMixin from "@/core/mixins/clientFormModalMixin.js";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";
import ClientFormModal from "@/view/layout/extras/modal/ClientFormModal.vue";
import { EventBus } from "@/core/services/event-bus.js";

export default {
  name: "Clients",
  components: { BBDropdown, BBSelect, ClientFormModal },
  mixins: [clientFormModalMixin, mobileAsideMixin],
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        {
          text: "",
          value: "initials",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("clients.table.columns.name"),
          value: "fullName",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("clients.table.columns.phone"),
          value: "phone",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("clients.table.columns.email"),
          value: "email",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          align: "right",
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        perPageOptions: [
          { text: "10", value: 10 },
          { text: "20", value: 20 },
          { text: "50", value: 50 },
        ],
        perPageDropdownIsOpen: false,
        currentFromItem: 0,
        currentToItem: 0,
        currentTotalItems: 0,
      },
    };
  },
  methods: {
    /**
     * Update pagination text based on tables pagination event
     * @param {Object} event
     * @return {void}
     */
    updatePaginationText(event) {
      this.pagination.currentFromItem = parseInt(event.pageStart) + 1;
      this.pagination.currentToItem = event.pageStop;
      this.pagination.currentTotalItems = event.itemsLength;
    },

    /**
     * Set value for items per page in table
     * @param {Number} perPage
     * @return {void}
     */
    setPerPageItems(perPage) {
      this.pagination.itemsPerPage = perPage;
    },

    /**
     * Delete the client
     * @param {Object} client
     * @return {void}
     */
    deleteClient(client) {
      EventBus.$emit("deletingClient", {
        client: client,
        successCallback: function () {
          this.$store.dispatch(GET_CLIENTS);
        },
      });
    },

    /**
     * Handle table row click
     * @param {Number} clientId
     * @return {void}
     */
    showClient(clientId) {
      this.$router.push({ name: "clientProfile", params: { id: clientId } });
    },
  },
  mounted() {
    // Dispatch store SET_BREADCRUMB action to set page title
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("clients.title") }]);
    // Dispatch store GET_CLIENTS action to get clients from api
    this.$store.dispatch(GET_CLIENTS);
  },
  computed: {
    ...mapGetters(["allClients"]),

    /**
     * Return array of clients to display
     * @return {Array}
     */
    displayClients() {
      if (this.allClients === 0) {
        return [];
      }
      return this.allClients.filter((client) =>
        client.fullName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/pages/clients.scss";
</style>
